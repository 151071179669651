.img-compare-container{
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-compare-after-img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
}

.img-compare-before-img {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0 , 50% 0, 50% 100%, 0 100%);
    object-fit: contain;
}

.img-compare-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.img-compare-slider-line {
    inset: 0;
    position: absolute;
    width: .2rem;
    background-color: #fff;
    z-index: 10;
    transform: translateX(-50%);
    pointer-events: none;
}

.img-compare-slider-button {
    position: absolute;
    background-color: #fff;
    color: #000;
    padding: .5rem;
    border-radius: 100vw;
    display: grid;
    place-items: center;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;
    box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
}