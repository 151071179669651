.project-selection-button {
    min-width: 200px;
    max-width: fit-content;
    border: none;
    padding: 0.5rem 0;
    width: auto;
    white-space: nowrap;
    font-size: 1.2rem;
    background-color: #242424;
    color: #fff;
}

.project-selection-button:hover {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.project-selection-button.project-button-selected {
    width: 100%;
    background-color: #fff;
    color: #242424;
}

@media screen and (max-width: 960px) {
    /* .project-selection-button {
        max-width: 70%;
    } */
}

@media screen and (max-width: 600px) {
    /* .project-selection-button {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    } */
}