.cards {
    padding: 4rem;
    background-color: #242424;
}
  
h1 {
    text-align: center;
    color: #fff;
}
  
.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}
  
.cards__wrapper {
    position: relative;
}
  
.cards__items {
    margin-bottom: 24px;
}
  
.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards-separator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 2rem 0;
}

.cards-separator {
    width: 40%;
    border-top: 2px solid #fff;
    display: block;
}

@media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
}
  
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
}