.modal-overlay {
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #242424e0;
    z-index: 1000;
    animation: fadeInModal 0.5s ease;
    display: flex;
    flex-direction: column;
}

@keyframes fadeInModal {
    0% {
        background-color: #24242400;
    }
    100% {
        background-color: #242424e0;
    }
}

.modal-close {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
}

.modal-close-button {
    color: #fff;
    font-size: 2rem;
}

.modal-close-button:hover {
    cursor: pointer;
    color: #aaa;
}

.modal-image-wrapper {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image-container {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

/* .modal-image-container::after {
    margin: auto;
    content: "";
    height: 2rem;
} */

.modal-image {
    /* width: 50%; */
    /* display: flex; */
    height: 100%;
    padding: 0 2rem 2rem 2rem;
    /* width: auto; */

}

.modal-image-zoom {
    animation: zoom-in 1s ease;
}

@keyframes zoom-in {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
}

.modal-video {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 100%;
}  