.about-why-container {
    width: 100%;
    padding: 2rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    background-color: #292929;
}

.about-why-title-container {
    width: 100%;
}

.about-why-title-container > h1 {
    font-size: 2.5rem;
}

.about-why-content-container {
    width: 100%;
}

.about-why-text-list {
    width: 100%;
    height: 100%;
    gap: 2rem;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    list-style-type: none;
    color: #fff;
}

.about-why-text-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%;
    height: 100%;
    padding-bottom: 2rem;
}

.about-why-text-item > h2 {
    font-size: 1.6rem;
    text-decoration: underline;
    color: #929292;
}

.about-why-text-item > h3 {
    font-size: 1.5rem;
    color: #d4d4d4;
}

.about-why-text-item > p {
    padding-top: 0.5rem;
    font-size: 1.4rem;
    text-wrap: wrap;
}

@media screen and (max-width: 960px) {
    .about-why-container {
        height: 100%;
        padding: 2rem 4rem;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }

    .about-why-separator {
        width: 0;
        align-self: stretch;
        border-right: 2px solid #fff;
        border-bottom: 0 ;
    }

    .about-why-text-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-why-text-item {
        align-items: center;
        width: 60vw;
    }
}

@media screen and (max-width: 600px) {
    .about-why-container {
        height: 100%;
        padding: 2rem 2rem;
        justify-content: space-between;
    }

    .about-why-text-item > p {
        text-align: center;
        font-size: 1rem;
    }
}