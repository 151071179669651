.about-container {
    background-color: #242424;
    width: 100%;
    display: flex;
}

.images-container {
    width: 40%;
}

.text-container {
    width: 60%;
    min-height: 500px;
    background-color: #242424;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.text-area {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.text-area > h1 {
    color: #fff;
}

.text-area > p {
    color: #fff;
    text-align: center;
}

@media screen and (max-width: 1270px) {
    .about-container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }

    .text-container {
        gap: 2rem;
    }

    .images-container {
        width: 90%;
    }

    .text-container {
        width: 100%;
        order: -1;
    }
}