.about-title-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #292929;
    padding: 2rem 5rem;
}

.about-title-wrapper {
    display: flex;
    width: 100%;
    max-width: 1350px;
    justify-content: space-between;
    align-items: center;
}

.about-title-container h1 {
    font-size: 3rem;
}

.about-title-container h2 {
    color: #fff;
}

h2 > a {
    text-decoration: none;
    color: #fff;
}

h2 > a:hover {
    color: #626262;
}

@media screen and (max-width: 960px) {
    .about-title-wrapper {
        justify-content: center;
    }
}