.tos-main-container {
    background-color: #242424;
    width: 100%;
    height: 100%;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.tos-main-wrapper {
    max-width: 1000px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.tos-main-info-container {
    font-size: 1.3rem;
}

.tos-main-info-container a {
    
    color: #bbbbbb;
    text-decoration: none;
}

.tos-main-info-container a:hover {
    color: #888888;
}

.tos-main-term-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
}

.tos-main-term-title {
    width: 100%;
    text-align: left;
    font-size: 2.4rem;
}

.tos-main-term-text {
    width: 95%;
}