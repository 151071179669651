.complete-radio-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radio-info-button {
    border: none;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    font-size: 1.1rem;
    transition: all 0.3s linear;
    color: #242424;
}

.radio-info-button:hover {
    cursor: pointer;
    background-color: #242424;
    color: #fff;
}

.radio-info-button.selected-radio-button {
    background-color: transparent;
    border: 2px solid #fff;
    color: white;
}

.selected-bottom-line {
    visibility: hidden;
    border-left: 2px solid #fff;
    height: 60px;
}

.display-line {
    visibility: visible;
}

@media only screen and (max-width: 1260px) {
    .radio-info-button {
        border: none;
        font-size: 0.9rem;
        height: 130px;
        width: 130px;
    }
}

@media only screen and (max-width: 1024px) {
    .radio-info-button {
        border: none;
        font-size: 0.7rem;
        height: 100px;
        width: 100px;
    }

    .selected-bottom-line {
        height: 40px;
    }
}

@media only screen and (max-width: 760px) {
    .complete-radio-button {
        flex-direction: row;
    }

    .selected-bottom-line {
        border-top: 2px solid #fff;
        height: 0px;
        width: 60px;
    }
}