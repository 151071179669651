.radio-container {
    display: flex;
    width: 100%;
    min-height: 600px;
    position: relative;

    background-image: url("/src/assets/images/pages/home_page/parallax.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.radio-container-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #242424;
    opacity: 0.8;
    z-index: 0;
}

.radio-content-container {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    z-index: 1;
}

.radio-content-container > h1 {
    color: #fff;
    font-size: 3rem;
}

.radio-info-buttons-container {
    width: 80%;
}

.radio-separator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 1.5rem 0;
}

.white.fa-circle {
    color: #fff;
}

.radio-separator {
    width: 30%;
    border-top: 2px solid #fff;
    display: block;
}

.bottom.radio-separator {
    width: 80%;
    border-top: 1px solid #fff;
}

.radio-info-buttons-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 65%;
}

.buttons-and-text {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-and-text > p {
    width: 70%;
    height: 100%;
    margin-top: 2rem;
    text-align: center;
    font-size: 1rem;
    color: #fff;
    overflow: hidden;
}

@media only screen and (max-width: 760px) {
    .radio-content-container {
        padding: 1.5rem;
    }
    
    .radio-info-buttons-container {
        width: auto;
        flex-direction: column;
        height: 100%;
        gap: 10px;
    }

    .bottom.radio-separator {
        width: 0;
        height: 60vh;
        border-right: 1px solid #fff;
    }

    .buttons-and-text {
        flex-direction: row;
    }

    .buttons-and-text > p {
        font-size: 0.8rem;
        margin-left: 1rem;
    }
}