/* .project-img-component-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
} */

.project-img-component-container {
    position: relative;
    width: 100%;
}

.project-figure-container {
    position: relative;
    display: flex;
    transition: all 0.3s linear;
    overflow: hidden;
}

.project-img-zoom-in {
    animation: zoom-in 1s ease;
}

.project-figure-container:hover {
    cursor: pointer;
}

.project-img {
    width: 100%;
    height: auto;
}

.project-figure-container img {
    width: 100%;
    height: auto;
}

.project-figure-container:hover img {
    transform: scale(1.3);
}

.project-figure-container:hover video {
    transform: scale(1.3);
}

.project-img-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.6rem;
    visibility: hidden;
}

.project-img-overlay > p {
    text-align: center;
}

.project-img-overlay > p {
    transition: 0.3s;
    transform: translateY(15px);
    opacity: 0;
}

.project-figure-container:hover > .project-img-overlay {
    visibility: visible;
}

.project-figure-container:hover > .project-img-overlay > p {
    opacity: 1;
    transform: translateY(0);
}

@keyframes zoom-in {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
}

/* @media screen and (max-width: 960px) {   
    .project-img-component-container {
        width: 40%;
    }
}

@media screen and (max-width: 600px) {   
    .project-img-component-container {
        width: 90%;
    }
} */