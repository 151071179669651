.floating-button-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: #686868;
    
    box-shadow: 5px 5px 5px hsl(0, 50%, 2%, .5);
    /* box-shadow: rgba(0, 0, 0, 1) 0px 20px 43px; */
    transform: translate3d(0px, -1px, 0px);
    transition: all .3s ease-out;

    z-index: 100;
}

.floating-button-container:hover {
    transform: translateY(-4px);
}

.floating-button-container > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
}

.floating-button-container > a:hover {
    cursor: pointer;
}

.fa-message {
    font-size: 2rem;
    color: #242424;
}

@media screen and (max-width: 600px) {
    .floating-button-container {
        width: 65px;
        height: 65px;
    }
}