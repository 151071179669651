.upload-overlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000aa;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #4a4a4a;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contact-form-container {
    width: 100%;
    background-color: #242424;
    display: grid;
    justify-items: center;
    /* padding: 4rem 0; */
    padding-top: 4rem;
}

.contact-form-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
}

.contact-form-wrapper {
    width: 80%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1.2fr .8fr;
    column-gap: 4rem;
}

.contact-form-title {
    width: fit-content;
}

.contact-form-content-wrapper {
    display: grid;
    grid-template-columns: .8fr 1.4fr;
    column-gap: 4rem;
}

.contact-form-content-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 220px;
}

.contact-form-info-field {
    font-size: 1.2rem;
    padding: 0.8rem 1rem;
    background-color: #1b1b1b;
    color: #fff;
    border-style: hidden;
    border-radius: .5rem;
    box-shadow: 3px 3px 3px hsl(0, 50%, 2%, .5);
}

.contact-form-content-message-wrapper {
    width: 100%;
    min-width: 200px;
}

.contact-form-message-field {
    width: 100%;
    height: 100%;
    padding: 0.8rem 1rem;
    background-color: #1b1b1b;
    border-style: hidden;
    border-radius: .5rem;
    color: white;
    box-shadow: 3px 3px 3px hsl(0, 50%, 2%, .5);
    resize: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form-submit-button {
    width: fit-content;
    font-size: 1.2rem;
    min-width: 220px;
    padding: .5rem 0;
    background-color: transparent;
    color: #fff;
    border-style: none;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}

.contact-form-submit-button:hover {
    cursor: pointer;
    color: #242424;
    background-color: #fff;
}

.contact-form-contact-info {
    color: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
}

.contact-form-contact-info-content {
    min-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.contact-form-contact-info-row {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 2px solid #1b1b1b;
}

@media screen and (max-width: 1180px) {
    .contact-form-content-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        min-width: 280px;

        row-gap: 2rem;
    }
}

@media screen and (max-width: 960px) {
    .contact-form-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        row-gap: 2rem;
    }

    .contact-form-button-wrapper {
        display: grid;
        justify-items: center;
    }

    .contact-form-contact-info {
        align-items: center;
    }

    .contact-form-contact-info-content p {
        text-align: center;
    }

    .contact-form-contact-info-row {
        justify-content: center;
    }
}