.service-section-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.service-section-color-lighter {
    background-color: #626262;
}

.service-section-color-darker {
    background-color: #242424;
}

.service-section-content-container {
    height: 100%;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-section-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    width: 80%;
    height: fit-content;
}

.service-section-content-wrapper > p {
    color: #fff;
}

.service-section-image-container {
    display: flex;
    width: 45%;
    height: 100%;
}

.service-image-left {
    order: -1;
}

.service-section-image-container img {
    width: 100%;
    height: 100%;
}

.service-section-image-container video {
    z-index: 1;
}

@media screen and (max-width: 1200px) {
    .service-section-content-wrapper {
        width: 70%;
    }

    .service-section-content-wrapper > p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 960px) {
    .service-section-content-wrapper {
        width: 80%;
    }

    .service-section-content-wrapper > p {
        font-size: 1rem;
    }

    .service-section-container {
        flex-direction: column;
        height: fit-content;
    }

    .service-section-image-container {
        width: 100%;
    }

    .service-section-content-container {
        order: 2;
        padding: 4rem 0;
        width: 80%;
    }    

    .service-section-content-wrapper {
        gap: 2rem;
    }
}