.about-content-container {
    background-color: #242424;
    width: 100%;
    display: flex;
}

.about-images-container {
    width: 25%;
}

.about-text-container {
    min-height: 500px;
    width: 75%;
    background-color: #242424;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.about-text-area {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.about-text-area > h1 {
    color: #fff;
}

.about-text-area > p {
    color: #fff;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .about-content-container{
        width: 100%;
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }

    .about-images-container {
        padding: 2rem 0rem;
        width: 70%;
    }

    .about-text-container {
        width: 100%;
        order: -1;
        gap: 2rem;
    }
}