.faq-main-container {
    width: 100%;
    height: 100%;
    padding: 3rem 2rem;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #242424;
}

.faq-question-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 1350px;
}

.faq-question-group h1 {
    font-size: 2.3rem;
    width: 100%;
    text-align: left;
}

.faq-questions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}
