/* Fix Cards and Hover */

.cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    background-color: #242424;
    width: 300px;
}
  
.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 10px rgba(255,255,255,0.2);
    -webkit-filter: drop-shadow(0 6px 10px rgba(255,255,255,0.2));
    filter: drop-shadow(0 6px 10px rgba(255,255,255,0.2));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}
  
.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.cards__item__pic-wrap:hover::after {
    content: attr(data-category);
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0,0,0,0.5);
}
  
.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    transition: all 0.2s linear;
    object-fit: fill;
}

.cards__item__img__wrapper {
    overflow: hidden; 
    width: 100%; 
    height: 100%; 
    display: flex;
}
  
.cards__item__pic-wrap:hover .cards__item__img {
    transform: scale(1.1);
    position: absolute;
}
  
.cards__item__info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 30px;
}
  
.cards__item__text {
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
}
  
@media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
}