.faq-question {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    font-size: 1.6rem;
    gap: 0.6rem;
    font-style: italic;
}

.faq-question:hover {
    cursor: pointer;
}

.faq-question-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0rem .5rem;
}

.rotate-question-button {
    rotate: -90deg;
}

.faq-question-answer {
    font-size: 1rem;
    padding: 1rem 2rem;

    overflow: hidden;
  
    transition: all .5s ease-in-out;
    line-height: 0;
    padding: 0 1em;
    color: transparent;
}

.faq-question-separator {
    width: 100%;
    border-bottom: 1px solid #ffffff;
}
   
.expandable-clicked {
    line-height: 1.5;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #ffffff;
}
