.error-page-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    background-color: #242424;
    align-items: center;
    justify-content: space-evenly;
}

.error-page-content-container > h1 {
    font-size: 5rem;
}

@media screen and (max-width: 600px) {
    .error-page-content-container > h1 {
        font-size: 4rem;
    }
}