.file-input-field {
    width: 100%;
    padding: 10px;
    background-color: #1b1b1b;
    box-shadow: 3px 3px 3px hsl(0, 50%, 2%, .5);
    border-radius: 5px;
    overflow: hidden;
}

.file-input-field .file-input-field-top {
    font-size: 1.2rem;
    text-align: center;
}

.file-input-field p {
    font-weight: bold;
    color: #fff;
}


.file-input-field-delete {
    z-index: 999;
    color: #fff;
}

.file-input-field .file-input-field-drag-area {
    height: 100px;
    border-radius: 5px;
    border: 2px dashed #242424;
    color: #242424;
    background-color: #f4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
}

.file-input-field .file-input-field-drag-area .file-input-field-visible {
    font-size: 18px;
}

.file-input-field .file-input-field-select {
    color: #707070;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.45s;
}

.file-input-field .file-input-field-select:hover {
    opacity: 0.6;
}

.file-input-field .file-input-field-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}

.file-input-field-info {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1.5rem;
    align-items: center;
}

.file-input-field .file-input-field-container .file-input-field-image {
    min-width: 75px;
    width: fit-content;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
    border-radius: .5rem;
    background-color: #242424;
    box-shadow: 3px 3px 3px hsl(0, 50%, 2%, .5);
    padding: 0 1.5rem;
}

.file-input-field .file-input-field-container .file-input-field-image .fa-file {
    color: #fff;
}

.file-input-field-file-name {
    color: #fff;
}

.file-input-field .file-input-field-container .file-input-field-image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.file-input-field input,
.file-input-field .file-input-field-drag-area .file-input-field-on-drop,
.file-input-field .file-input-field-drag-area.file-input-field-drag-over .file-input-field-visible {
    display: none;
}