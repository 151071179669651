.faq-title-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #292929;
    padding: 2rem 5rem;
}

.faq-title-wrapper {
    display: flex;
    width: 100%;
    max-width: 1350px;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq-title-container h1 {
    font-size: 4rem;
}

.faq-title-container h2 {
    font-size: 2.4rem;
    text-align: center;
    color: #ffffff;
}