.projects-content-container {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #242424;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-selection-buttons-container {
    width: 80%;
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content-images-container {
    padding: 0 5rem;
    display: grid;
    gap: 1rem;

    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-rows: auto;
}

.content-images-row {
    min-height: 60vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 0 4rem;
}

.content-images-column {
    display: flex;
    flex-direction: column;
    flex: 22.5%;
    max-width: 22.5%;
    min-width: 300px;
    gap: 1rem;
}

.project-video {
    position: relative;
    height: auto;
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .content-images-column {
        min-width: 500px;
    }
}

@media screen and (max-width: 1160px) {
    .content-images-column {
        min-width: 400px;
    }
}

@media screen and (max-width: 1060px) {
    .content-images-container {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 600px) {
    .content-selection-buttons-container {
        padding: 2rem 1rem;
    }

    .content-images-column {
        min-width: 300px;
    }
}