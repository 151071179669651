.slideshow {
    width: 100%;
    height: 100%;
}

.slideshow-slider {
    display: grid;
    grid-template-columns: 1fr;
}

.slide {
    width: 100%;
    grid-row-start: 1;
    grid-column-start: 1;

    transition: ease 1.5s;
}